.sidebar .nav-item{
    background: none !important;
    border: none !important;
}
.sidebar .nav-item.active{
    border: none !important;
}

.react-confirm-alert-button-group > button {
    padding: 10px 43px !important;
}
.react-confirm-alert-button-group > button :first-child {
    background-color: red !important;
}

.react-confirm-alert-button-group {
    justify-content: space-around !important;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    display: flex !important;
    justify-content: space-around !important;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select, .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
    padding: 10px 0 !important;
}

select.react-datepicker__month-select, select.react-datepicker__year-select{
    display: block;
    width: 100%;
    padding: 0.55rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #66799e;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0.0625rem solid #d1d7e0;
    appearance: none;
    border-radius: 0.5rem;
    box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select.react-datepicker__year-select{
    padding: 0.55rem 1.75rem !important;
}

span.read-more-less--more{
    color: blue !important;
}
span.read-more-less--less{
    color: blue !important;
}

.drag-table-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    padding: 14px 20px;
    background: #333;
    color: #fff;
}

.drag-table-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
}